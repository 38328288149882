<template>
  <div class="buy-log-view">
    <BuyLogList
      :buys="dailyBuys"
      :loading="isLoading"
      @click:buy="onBuyClick"
    />
    <router-view name="reportDialog" />
  </div>
</template>

<script>
import { DAILY_BUYS } from "./graphql";
import BuyLogList from "./components/BuyLogList.vue";

export default {
  name: "BuyLogView",
  components: { BuyLogList },
  props: {
    displayDate: {
      type: String,
      required: true,
    },
    storeId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    dailyBuys: [],
  }),
  apollo: {
    dailyBuys: {
      query: DAILY_BUYS,
      variables() {
        return { storeId: this.storeId, businessDate: this.displayDate };
      },
      skip() {
        return !this.storeId || !this.displayDate;
      },
    },
  },
  computed: {
    isLoading() {
      return this.$apollo.queries.dailyBuys.loading;
    },
  },
  methods: {
    onBuyClick(buy) {
      this.$router.push({
        name: "reports.buylog.buy",
        params: { buyId: buy.id },
        preserveQuery: true,
      });
    },
  },
};
</script>
